<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="12" sm="12">
                    <b-table-simple bordered>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('org_pro.organization') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomType.org_bn : roomType.org }}</b-td>
                                <b-th style="width:10%; vertical-align:middle" rowspan="2">{{ $t('committee.attachment') }}</b-th>
                                <b-th style="width:40%" rowspan="2"><img :src="trainingElearningServiceBaseUrl + 'storage/uploads/config/room-type/' + roomType.attachment" style="width: 190px; height: 120px; border-radius: 0px !important;"></b-th>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.office_type') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomType.office_bn : roomType.office }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('org_pro.office_namel') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomType.office_name_bn : roomType.office_name }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.guest_house') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomType.guestHouse_bn : roomType.guestHouse }}</b-td>
                            </b-tr>
                            <b-tr>
                                <b-th style="width:20%">{{ $t('elearning_config.room_type') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomType.room_type_bn : roomType.room_type_en }}</b-td>
                                <b-th style="width:20%">{{ $t('elearning_config.remarks') }}</b-th>
                                <b-td style="width:30%">{{ ($i18n.locale=='bn') ? roomType.remarks_bn : roomType.remarks }}</b-td>
                            </b-tr>
                    </b-table-simple>
                  </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<script>
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
export default {
  name: 'FormLayout',
  props: ['item'],
    created () {
        this.roomType = this.item
    },
    data () {
        return {
            roomType: [],
            slOffset: 1,
            trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
        }
    },
    computed: {
    },
  methods: {
    // getRoomTypeData () {
    //   const tmpData = this.$store.state.list.find(item => item.id === this.id)
    //   return JSON.parse(JSON.stringify(tmpData))
    // }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
